import React from "react";
import Link from "next/link";
import { DEFAULT_SPORT } from "../../constants";
import styles from "./styles.scss";

function FeaturedPages() {
  return (
    <div className="featured-pages">
      <div className="card">
        <h1 className="title">Odds</h1>
        <p className="text">
          Get all odds including spread, moneyline, total, 1st half spread, 2nd half spread and more.
        </p>
        <Link href={`/${DEFAULT_SPORT.url}/odds`} legacyBehavior>
          <a className="button">Get Odds</a>
        </Link>
      </div>
      <div className="card">
        <h1 className="title">Public</h1>
        <p className="text">Want to know who the public is betting on?</p>
        <Link href={`/${DEFAULT_SPORT.url}/public-betting`} legacyBehavior>
          <a className="button">Get Public</a>
        </Link>
      </div>
      <div className="card">
        <h1 className="title">Pro Bettor Report</h1>
        <p className="text">Want to know who the professional bettors are putting their money on?</p>
        <Link href={`/${DEFAULT_SPORT.url}/sharp-picks`} legacyBehavior>
          <a className="button">Get Sharp</a>
        </Link>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
}

export default FeaturedPages;
