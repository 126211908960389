import React from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { DEFAULT_BOOK_ID } from "@rotoql/common-services";
import { getDefaultAfterDateRange, getDefaultBeforeDateRange } from "src/services/DateService";
import { generateEventCardDisplayProps, selectGames } from "src/services/EventService";
import { INDEX_EVENTS } from "src/gql/queries";
import { WEB_SPORTS_MAP, WEB_HEADER_SPORTS_NAME_LIST, DEFAULT_TIMEZONE } from "src/constants";

import LeagueHeading from "../LeagueHeading";
import { SportGames } from "../SportGames";
import { getSchedule } from "src/services/ScheduleService";

export const getGameIndexVars = async () => {
  const nflSchedule = await getSchedule("nfl");
  const nflAfter = getDefaultAfterDateRange("nfl", nflSchedule);
  const nflBefore = getDefaultBeforeDateRange("nfl", nflSchedule);
  const ncaafSchedule = await getSchedule("ncaaf");
  const cfbAfter = getDefaultAfterDateRange("ncaaf", ncaafSchedule);
  const cfbBefore = getDefaultBeforeDateRange("ncaaf", ncaafSchedule);
  const nbaSchedule = await getSchedule("nba");
  const nbaBefore = getDefaultBeforeDateRange("nba", nbaSchedule);
  const nbaAfter = getDefaultAfterDateRange("nba", nbaSchedule);
  const nhlSchedule = await getSchedule("nhl");
  const nhlBefore = getDefaultBeforeDateRange("nhl", nhlSchedule);
  const nhlAfter = getDefaultAfterDateRange("nhl", nhlSchedule);
  const xflSchedule = await getSchedule("xfl");
  const xflBefore = getDefaultBeforeDateRange("xfl", xflSchedule);
  const xflAfter = getDefaultAfterDateRange("xfl", xflSchedule);
  const debSchedule = await getSchedule("deb");
  const debAfter = getDefaultAfterDateRange("deb", debSchedule);
  const debBefore = getDefaultBeforeDateRange("deb", debSchedule);
  const eplSchedule = await getSchedule("epl");
  const eplAfter = getDefaultAfterDateRange("epl", eplSchedule);
  const eplBefore = getDefaultBeforeDateRange("epl", eplSchedule);
  const espSchedule = await getSchedule("esp");
  const espAfter = getDefaultAfterDateRange("esp", espSchedule);
  const espBefore = getDefaultBeforeDateRange("esp", espSchedule);
  const itsaSchedule = await getSchedule("nhl");
  const itsaAfter = getDefaultAfterDateRange("itsa", itsaSchedule);
  const itsaBefore = getDefaultBeforeDateRange("itsa", itsaSchedule);
  const uclSchedule = await getSchedule("ucl");
  const uclAfter = getDefaultAfterDateRange("ucl", uclSchedule);
  const uclBefore = getDefaultBeforeDateRange("ucl", uclSchedule);
  const mlsSchedule = await getSchedule("mls");
  const mlsAfter = getDefaultAfterDateRange("mls", mlsSchedule);
  const mlsBefore = getDefaultBeforeDateRange("mls", mlsSchedule);
  const before = moment().tz(DEFAULT_TIMEZONE).endOf("day").utc();
  const after = moment().tz(DEFAULT_TIMEZONE).startOf("day").utc();
  return {
    nflAfter,
    // if the season is over, set the before/after equal so no events show
    // this will prevent us from having turn this on and off every season
    nflBefore: moment(nflBefore).isAfter() ? nflBefore : nflAfter,
    cfbAfter,
    cfbBefore: moment(cfbBefore).isAfter() ? cfbBefore : cfbAfter,
    nbaAfter,
    nbaBefore: moment(nbaBefore).isAfter() ? nbaBefore : nbaAfter,
    nhlAfter,
    nhlBefore: moment(nhlBefore).isAfter() ? nhlBefore : nhlAfter,
    xflAfter,
    xflBefore: moment(xflBefore).isAfter() ? xflBefore : xflAfter,
    debAfter,
    debBefore: moment(debBefore).isAfter() ? debBefore : debAfter,
    eplAfter,
    eplBefore: moment(eplBefore).isAfter() ? eplBefore : eplAfter,
    espAfter,
    espBefore: moment(espBefore).isAfter() ? espBefore : espAfter,
    itsaAfter,
    itsaBefore: moment(itsaBefore).isAfter() ? itsaBefore : itsaAfter,
    uclAfter,
    uclBefore: moment(uclBefore).isAfter() ? uclBefore : uclAfter,
    mlsAfter,
    mlsBefore: moment(mlsBefore).isAfter() ? mlsBefore : mlsAfter,
    before,
    after,
    bookId: DEFAULT_BOOK_ID, // Consensus
  };
};

export const useGamesIndexData = () => {
  const gamesResponse = useQuery(INDEX_EVENTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: getGameIndexVars(),
  });
  return gamesResponse.data;
};

function GamesIndex(props: { games: any }) {
  let sportDisplays: any = [];
  let showEmpty = true;
  const now = moment().tz(DEFAULT_TIMEZONE).utc();

  WEB_HEADER_SPORTS_NAME_LIST.forEach((sportName: string) => {
    const sportingEvents = _.cloneDeep(props.games ?? {});
    const camelSportName = _.camelCase(sportName);
    let sportEvents = sportingEvents?.[`${camelSportName}Events`] ?? [];

    if (sportEvents) {
      const currentSportObj = WEB_SPORTS_MAP[sportName];
      const sportDisplayList: Array<any> = selectGames(now, sportEvents, 6);

      sportEvents = sportDisplayList.map((event: any) =>
        generateEventCardDisplayProps(event, currentSportObj.lookupKey, DEFAULT_BOOK_ID)
      );

      sportDisplays.push(
        sportEvents.length ? (
          <SportGames league={currentSportObj} events={sportEvents} key={currentSportObj.key} />
        ) : null
      );

      if (showEmpty && sportEvents.length) {
        showEmpty = false;
      }
    }
  });

  return showEmpty ? <LeagueHeading /> : sportDisplays;
}

export default GamesIndex;
