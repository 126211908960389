export default [
  {
    name: "Week 1",
    startDate: "2020-02-08",
    endDate: "2020-02-10",
  },
  {
    name: "Week 2",
    startDate: "2020-02-15",
    endDate: "2020-02-17",
  },
  {
    name: "Week 3",
    startDate: "2020-02-22",
    endDate: "2020-02-24",
  },
  {
    name: "Week 4",
    startDate: "2020-02-29",
    endDate: "2020-03-02",
  },
  {
    name: "Week 5",
    startDate: "2020-03-07",
    endDate: "2020-03-09",
  },
  {
    name: "Week 6",
    startDate: "2020-03-14",
    endDate: "2020-03-16",
  },
  {
    name: "Week 7",
    startDate: "2020-03-21",
    endDate: "2020-03-23",
  },
  {
    name: "Week 8",
    startDate: "2020-03-28",
    endDate: "2020-03-30",
  },
  {
    name: "Week 9",
    startDate: "2020-04-02",
    endDate: "2020-04-06",
  },
  {
    name: "Week 10",
    startDate: "2020-04-09",
    endDate: "2020-04-13",
  },
  {
    name: "Playoffs",
    startDate: "2020-04-18",
    endDate: "2020-04-20",
  },
  {
    name: "Championship",
    startDate: "2020-04-26",
    endDate: "2020-04-27",
  },
];
