import React, { useState, useRef, useEffect } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { POPULAR_EVENTS_QUERY } from "src/gql/queries";
import styles from "./styles.scss";
import Router from "next/router";
import { WEB_SPORTS_LIST, POPULAR_EVENTS_SELECTOR_CONFIG } from "src/constants";
import { useCarousel } from "src/components/Carousel";
import TeamLogo from "src/components/Team/TeamLogo";
import Dropdown, { DropdownItem } from "src/components/Dropdown";
import AnalyticsService from "src/services/AnalyticsService";
import Tooltip from "src/components/Tooltip";
import CountAnimation from "src/components/CountAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import FocusedTextLink from "../FocusedTextLink";
import { useGameProgressAndChannel } from "src/services/EventService";
import { currentSport } from "src/services/ApolloService";

// NOTE: key is DB league name. text is display name
const leagueOptions: DropdownItem[] = [{ key: null as any, name: "All Sports" }, ...POPULAR_EVENTS_SELECTOR_CONFIG];

const getLeagueFromName = (name: string) => WEB_SPORTS_LIST.find((x) => x.key === name);

const PopularEvents = (props: any) => {
  const currentSportObj = useReactiveVar(currentSport);
  const leagueInDropdown = Boolean(leagueOptions.find((x) => x.key === currentSportObj?.key));
  const [league, setLeague] = useState<string | null>(
    (!props.isHomepage && leagueInDropdown && currentSportObj?.key) || null
  );
  const { data, loading } = useQuery(POPULAR_EVENTS_QUERY, {
    ssr: props.ssr || false,
    variables: { league },
    pollInterval: 1000 * 60 * 10, // 10min
    fetchPolicy: "no-cache",
  });
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!props.isHomepage && leagueInDropdown) {
      setLeague(currentSportObj?.key ?? null);
    } else {
      setLeague(null);
    }
  }, [currentSportObj?.key]);

  // Container query allows placement in sidebar
  const onResize = ({ width }: any) => {
    if (width > 575) {
      containerRef.current?.style.setProperty("--mobile", "invalidate-property");
    } else {
      containerRef.current?.style.setProperty("--mobile", "");
    }
  };

  const events = data?.popularEvents ?? [];
  const eventComponents = loading
    ? [1, 2, 3, 4, 5, 6].map((i) => <PopularEventPlaceholder dark={props.dark} key={i} />)
    : events.map((event: any) => <PopularEvent event={event} />);

  const { Carousel, CarouselNav } = useCarousel(eventComponents, {
    onResize,
    contentHash: String(loading) + events.map((event: any) => event.id + event.visitorCount).join(""),
  });

  return (
    <div className={`popular-events ${props.className || ""}`} ref={containerRef}>
      {/* Header (title and dropdown) */}
      <div className="header">
        <span className="header-title">
          <h2>Most Popular Right Now</h2>
          <Tooltip title="Most Popular Right Now">
            <p className="tooltip-text">
              Popular events based on current user activity. The list updates in real time, with recent activity more
              heavily weighted.
            </p>
          </Tooltip>
        </span>
        <Dropdown
          className="sport-dropdown"
          items={leagueOptions}
          selectedIndex={leagueOptions.findIndex((o) => o.key === league)}
          onSelectItem={(o) => setLeague(o.key as string | null)}
        />
        <CarouselNav />
      </div>
      <Carousel />
      {!loading && !events.length && (
        <div className="no-events">
          <div>
            There are currently no popular events for this sport. View the 'All' section or another sport to see more
            events.
          </div>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default PopularEvents;

const PopularEventPlaceholder = ({ dark }: { dark: boolean }) => {
  const cardStyle = dark ? { background: "#FFFFFF88", opacity: 0.1 } : {};
  return (
    <div className="card" style={{ height: 185, ...cardStyle }}>
      <div className="visitor-stats">
        <div className="visitor-count placeholder" style={{ width: 130 }}>
          &nbsp;
        </div>
        <div className="visitor-increase placeholder" style={{ width: 45, height: 24 }} />
      </div>
      <div className="teams">
        <div className="team">
          <div className="team-logo placeholder" />
          <div className="team-abbrev placeholder" style={{ width: 30 }}>
            &nbsp;
          </div>
        </div>
        <div className="placeholder" style={{ width: 18, height: 18 }} />
        <div className="team">
          <div className="team-logo placeholder" />
          <div className="team-abbrev placeholder" style={{ width: 30 }}>
            &nbsp;
          </div>
        </div>
      </div>
      <div className="time placeholder">&nbsp;</div>
      <div className="cta-button placeholder">&nbsp;</div>
    </div>
  );
};

// Popular Event Card
const PopularEvent = ({ event }: { event: any }) => {
  const homeAbbrev = event.homeTeam?.preferredAbbreviation;
  const awayAbbrev = event.awayTeam?.preferredAbbreviation;

  const onSeeFullAnalysis = () => () => {
    const sportSlug = getLeagueFromName(event.league.name)?.url;

    Router.push(`/game?sport=${sportSlug}&slug=${event.slugId}`, `/${sportSlug}/game-predictions/${event.slugId}`).then(
      () => window.scrollTo(0, 0)
    );

    AnalyticsService.track("Open Game", {
      platform: "web",
      page: window.location.href,
      component: "PopularEvents",
      league: event.league.name,
    });
  };

  const makeTeamLogo = (teamAbbreviation: any) => (
    <TeamLogo
      containerClassName="team-logo"
      leagueName={getLeagueFromName(event.league.name)?.imagePath ?? ""}
      teamAbbreviation={teamAbbreviation}
      size={21}
    />
  );

  const visitorsMarkup = (
    <div className="visitor-stats">
      <div className="visitor-count">
        <CountAnimation
          // If we're going to "beef up" our numbers, let's not make it so obvious
          countTo={event.visitorCount * 10 + Math.round(Math.random() * 10)}
          animationDuration={2500}
        />{" "}
        visiting here
      </div>
      <div className="visitor-increase">
        <FontAwesomeIcon icon={faChartLine} style={{ fontSize: 18 }} />{" "}
        <CountAnimation countTo={event.visitorTrend} animationDuration={2000} />%
      </div>
    </div>
  );

  const eventMarkup = (
    <div className="teams">
      {/* Home Team */}
      <div className="team">
        {makeTeamLogo(homeAbbrev)}
        <p className="team-abbrev">{homeAbbrev}</p>
      </div>
      vs
      {/* Away Team */}
      <div className="team">
        {makeTeamLogo(awayAbbrev)}
        <p className="team-abbrev">{awayAbbrev}</p>
      </div>
    </div>
  );

  const progressAndChannel = useGameProgressAndChannel(event);
  const timeMarkup = <p className="time">{progressAndChannel}</p>;

  const dividerMarkup = <div className="divider" />;

  const ctaMarkup = (
    <FocusedTextLink onClick={onSeeFullAnalysis()} className="cta-button">
      See Full Analysis
    </FocusedTextLink>
  );

  return (
    <div className="card">
      {visitorsMarkup}
      {eventMarkup}
      {timeMarkup}
      {dividerMarkup}
      {ctaMarkup}
    </div>
  );
};
