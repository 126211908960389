import NFLSchedule from "./NFLSchedule";
import NCAAFSchedule from "./NCAAFSchedule";
import XFLSchedule from "./XFLSchedule";
import MLSSchedule from "./MLSSchedule";
import NCAABTSchedule from "./NCAABTSchedule";
import NCAABNITSchedule from "./NCAABNITSchedule";

import { initializeApollo } from "src/lib/apollo-client";
import { gql } from "@apollo/client";
import { NCAA_TOURNAMENT_STARTED } from "../../constants";

const CONFERENCE_SCHEDULE_TOURNAMENTS = ["NCAAT", "NIT", "nit-tournament", "ncaa-tournament"];
export interface ScheduleItem {
  name: string;
  startDate: string;
  endDate: string;
  matchupSlugIds?: string[];
  skip?: boolean;
}

export const hasConfiguredConferenceSchedule = (conference?: string | null) =>
  NCAA_TOURNAMENT_STARTED && CONFERENCE_SCHEDULE_TOURNAMENTS.includes(conference ?? "");

export const getSchedule = async (sportKey: string): Promise<ScheduleItem[]> => {
  switch (sportKey) {
    case "nfl":
      return NFLSchedule;
    case "ncaaf":
      return NCAAFSchedule;
    case "xfl":
      return XFLSchedule;
    case "mls":
      return MLSSchedule;
    case "deb":
    case "epl":
    case "esp":
    case "itsa":
    case "ucl":
      return await getEuropeanSoccerSchedule(sportKey.toUpperCase());
    default:
      return NFLSchedule;
  }
};

export const getConferenceSchedule = (conference?: string): ScheduleItem[] | null => {
  switch (conference) {
    case "NCAAT":
      return NCAABTSchedule;
    case "NIT":
      return NCAABNITSchedule;
  }

  return null;
};

const getEuropeanSoccerSchedule = async (leagueEnum: string): Promise<ScheduleItem[]> => {
  const { data } = await initializeApollo().query({
    query: gql`
      query getEuropeanSoccerSchedule($leagueEnum: LeagueEnum!) {
        getEuropeanSoccerGameweekDates(leagueEnum: $leagueEnum) {
          seasonId
          weekId
          weekName
          startDate
          endDate
          matchupSlugIds
        }
      }
    `,
    variables: {
      leagueEnum,
    },
  });
  interface Gameweek {
    seasonId: string;
    weekId: string;
    weekName: string;
    startDate: string;
    endDate: string;
    matchupSlugIds?: string[];
  }
  return data.getEuropeanSoccerGameweekDates.map((gameweek: Gameweek) => ({
    name: gameweek.weekName,
    startDate: gameweek.startDate,
    endDate: gameweek.endDate,
    matchupSlugIds: gameweek.matchupSlugIds,
  }));
};
