import React, { useContext } from "react";
import { NextSeo, SocialProfileJsonLd } from "next-seo";
import { DEFAULT_SPORT } from "../constants";
import styles from "../styles/index.scss";
import LiveGameBar from "../components/LiveGameBar";
import Hero from "../components/Hero";
import { LatestArticles } from "../components/ArticleGrid";
import FeaturedPages from "../components/FeaturedPages";
import OffersCarousel from "../components/OffersCarousel";
import GamesIndex, { getGameIndexVars, useGamesIndexData } from "../components/GamesIndex";
import FeaturedTrends from "src/components/FeaturedTrends";
import Surveys from "../components/Surveys";
import VideoGallery from "../components/VideoGallery";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { useIndexArticles } from "src/services/FeaturedContent";
import { addApolloState, initializeApollo } from "src/lib/apollo-client";
import { INDEX_EVENTS, INFINITE_ARTICLES_QUERY, LEAGUE_CONFIG_QUERY } from "src/gql/queries";
import HostSavedPicks from "src/components/HostSavedPicks";

export const getStaticProps: GetStaticProps<{}> = async ({ previewData }) => {
  const apolloClient = initializeApollo();
  let context: any = {};
  if (previewData) {
    context.headers = {
      "Prismic-ref": (previewData as { ref: string })?.ref,
    };
  }
  // Load query results into apollo cache for hydration
  await apolloClient.query({
    query: LEAGUE_CONFIG_QUERY,
    variables: {
      league: "none",
      includeSurveyResponses: false,
    },
    context,
  });
  await apolloClient.query({
    query: INFINITE_ARTICLES_QUERY,
    variables: {
      first: 9,
      where: {
        hidden: false,
        league: undefined,
      },
    },
    context,
  });
  await apolloClient.query({
    query: INDEX_EVENTS,
    variables: await getGameIndexVars(),
    context,
  });
  return addApolloState(apolloClient, {
    props: {},
    revalidate: 10 * 60, // 10 mins
  });
};

export default function Index({}: InferGetStaticPropsType<typeof getStaticProps>) {
  const ctx = useContext(SubscriptionContext);
  const articles = useIndexArticles({});
  const games = useGamesIndexData();
  return (
    <React.Fragment>
      <NextSeo
        title="BetQL | Sports Betting Picks, Predictions, and Articles"
        openGraph={{
          url: "https://www.betql.co",
          title: "BetQL | Sports Betting Picks, Predictions, and Articles",
        }}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="BetQL"
        url="https://betql.co"
        sameAs={[
          "https://www.facebook.com/betql/",
          "https://www.instagram.com/betqlapp",
          "https://www.linkedin.com/company/rotoql",
          "https://twitter.com/betqlapp",
        ]}
      />
      <LiveGameBar sport={DEFAULT_SPORT} />
      <div id="main">
        <Hero />
        <FeaturedTrends isHomepage />
        <section className={`home-page ${ctx.signedIn ? "signed-in" : ""}`}>
          <HostSavedPicks />
          <FeaturedPages />
          <section id="video" className="video-gallery-section">
            <h1 className="heading">Latest News &amp; Betting Analysis</h1>
            <p className="subheading">
              Every day we provide you with betting insights live from the BetQL Audio Network.
            </p>
            <div style={{ height: 12 }} />
            <VideoGallery />
          </section>
          <LatestArticles articles={articles} />
          <OffersCarousel headingStyle={{ fontSize: "22px", fontWeight: 900 }} />
          <GamesIndex games={games} />
          <Surveys header={<h2 className="heading">Latest Polls</h2>} />
          <style jsx global>
            {styles}
          </style>
        </section>
      </div>
    </React.Fragment>
  );
}
