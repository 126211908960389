import React, { useContext } from "react";
import Link from "next/link";
import { SubscriptionContext } from "../../services/SubscriptionService";
import { renderRatingByValue } from "../../services/ValueDataService";
import { useCarousel } from "../Carousel";
import { AffiliateLink, getOfferId, OfferItem, prepareOfferStates, StateItem } from "src/services/OfferService";
import AnalyticsService from "src/services/AnalyticsService";
import styles from "./styles.scss";
import { useQuery } from "@apollo/client";
import { OFFERS_QUERY } from "src/gql/queries";

function OfferPlaceholder() {
  return (
    <div className="offer-container" style={{ height: 313 }}>
      <div className="offer-image placeholder" />
      <div className="offer-rating placeholder" style={{ width: 120, height: 24, margin: "0 auto" }} />
      <div className="offer-description placeholder" style={{ height: 66, width: "100%" }} />
      <div className="offer-button placeholder">&nbsp;</div>
      <div className="offer-link placeholder" style={{ width: 77 }}>
        &nbsp;
      </div>
    </div>
  );
}

function Offer({ offer, state }: { offer: OfferItem; state: StateItem }) {
  function trackEvent(name: string) {
    AnalyticsService.track(name, {
      sportsbook: offer.sportsbook_name,
      affiliateLink: offer.web_link,
      offerText: offer.welcome_offer,
      howText: offer.how_it_works,
      state: state.name,
      page: window.location.href,
      component: "OffersCarousel",
    });
  }

  return (
    <div className="offer-container" key={getOfferId(offer)} style={{ pointerEvents: "auto" }}>
      <img className="offer-image lazyload" data-src={offer.image.url} alt={offer?.welcome_offer} />
      <div className="offer-rating">{renderRatingByValue(offer.rating, false, "offer-rating-stars")}</div>
      <div className="offer-description">{offer.welcome_offer}</div>
      <AffiliateLink
        href={offer.web_link}
        target="_blank"
        rel="noopener"
        className="offer-button"
        onClick={() => trackEvent("Claim Offer")}
      >
        Claim Offer
      </AffiliateLink>
      <Link href="/sportsbook-offers" passHref legacyBehavior>
        <a className="offer-link" onClick={() => trackEvent("Learn More")}>
          Learn&nbsp;More.
        </a>
      </Link>
      <style jsx>{styles}</style>
    </div>
  );
}

function OffersCarousel(props: { headingStyle?: any }) {
  const { userLocale } = useContext(SubscriptionContext);
  const response = useQuery(OFFERS_QUERY, { ssr: false });
  const loading = response.loading;
  const { data: offerStates } = prepareOfferStates(response);
  const stateIndex = Math.max(0, offerStates.findIndex((state) => state.abbreviation === userLocale)); // prettier-ignore
  const offerState = offerStates[stateIndex];
  const offers = offerState?.sportsbook_offers ?? [];

  // Populate carousel
  const carouselItems = loading
    ? [1, 2, 3, 4, 5, 6, 7].map((i) => <OfferPlaceholder key={i} />)
    : offers.map((offer: any, i: number) => <Offer key={i} offer={offer} state={offerState} />);

  const { Carousel, CarouselNav } = useCarousel(carouselItems, {
    contentHash: String(loading) + offers.map((offer) => getOfferId(offer)).join(""),
  });

  // Loaded and no offers (never the case currently)
  if (!loading && !offers.length) {
    return null;
  }

  return (
    <section className="offers-carousel" aria-label="Offers Carousel">
      <div className="offers-nav">
        <div className="offers-heading" style={props.headingStyle}>
          Redeem Exclusive Offers For {offerState?.name ?? ""}
        </div>
        <CarouselNav />
      </div>
      <Carousel />
      <div className="offers-footer">
        <Link href="/sportsbook-offers" passHref legacyBehavior>
          <a className="offer-link">See All Sportsbook Offers</a>
        </Link>
      </div>
      <style jsx>{styles}</style>
    </section>
  );
}

export default OffersCarousel;
