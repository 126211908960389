export default [
  {
    name: "HOF",
    startDate: "2024-07-31",
    endDate: "2024-08-08",
    skip: true,
  },
  {
    name: "Pre 1",
    startDate: "2024-08-08",
    endDate: "2024-08-12",
    skip: true,
  },
  {
    name: "Pre 2",
    startDate: "2024-08-13",
    endDate: "2024-08-20",
    skip: true,
  },
  {
    name: "Pre 3",
    startDate: "2024-08-20",
    endDate: "2024-08-27",
    skip: true,
  },
  {
    name: "Week 1",
    startDate: "2024-08-28",
    endDate: "2024-09-10",
  },
  {
    name: "Week 2",
    startDate: "2024-09-10",
    endDate: "2024-09-17",
  },
  {
    name: "Week 3",
    startDate: "2024-09-17",
    endDate: "2024-09-24",
  },
  {
    name: "Week 4",
    startDate: "2024-09-24",
    endDate: "2024-10-01",
  },
  {
    name: "Week 5",
    startDate: "2024-10-01",
    endDate: "2024-10-08",
  },
  {
    name: "Week 6",
    startDate: "2024-10-08",
    endDate: "2024-10-15",
  },
  {
    name: "Week 7",
    startDate: "2024-10-15",
    endDate: "2024-10-22",
  },
  {
    name: "Week 8",
    startDate: "2024-10-22",
    endDate: "2024-10-29",
  },
  {
    name: "Week 9",
    startDate: "2024-10-29",
    endDate: "2024-11-05",
  },
  {
    name: "Week 10",
    startDate: "2024-11-05",
    endDate: "2024-11-12",
  },
  {
    name: "Week 11",
    startDate: "2024-11-12",
    endDate: "2024-11-19",
  },
  {
    name: "Week 12",
    startDate: "2024-11-19",
    endDate: "2024-11-26",
  },
  {
    name: "Week 13",
    startDate: "2024-11-26",
    endDate: "2024-12-03",
  },
  {
    name: "Week 14",
    startDate: "2024-12-03",
    endDate: "2024-12-10",
  },
  {
    name: "Week 15",
    startDate: "2024-12-10",
    endDate: "2024-12-17",
  },
  {
    name: "Week 16",
    startDate: "2024-12-17",
    endDate: "2024-12-24",
  },
  {
    name: "Week 17",
    startDate: "2024-12-24",
    endDate: "2024-12-31",
  },
  {
    name: "Week 18",
    startDate: "2024-12-31",
    endDate: "2025-01-07",
  },
  {
    name: "Wild Card",
    startDate: "2025-01-07",
    endDate: "2025-01-14",
  },
  {
    name: "Div Round",
    startDate: "2025-01-14",
    endDate: "2025-01-21",
  },
  {
    name: "Conf Champ",
    startDate: "2025-01-21",
    endDate: "2025-01-27",
  },
  {
    name: "Pro Bowl",
    startDate: "2025-02-21",
    endDate: "2025-02-05",
    skip: true,
  },
  {
    name: "Super Bowl",
    startDate: "2025-02-09",
    endDate: "2025-02-11",
  },
];
