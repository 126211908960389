// TODO update this for NCAA Tournament in march
export default [
  {
    name: "Round 1",
    startDate: "2024-03-17T04:00:00Z",
    endDate: "2024-03-21T03:59:59Z",
  },
  {
    name: "Round 2",
    startDate: "2024-03-21T04:00:00Z",
    endDate: "2024-03-25T03:59:59Z",
  },
  {
    name: "Quaterfinals",
    startDate: "2024-03-25T04:00:00Z",
    endDate: "2024-03-28T03:59:59Z",
  },
  {
    name: "Semifinals",
    startDate: "2024-03-28T04:00:00Z",
    endDate: "2024-04-03T03:59:59Z",
  },
  {
    name: "Championship",
    startDate: "2024-04-03T04:00:00Z",
    endDate: "2024-04-05T03:59:59Z",
  },
];
