// TODO update this for NCAA Tournament in march
export default [
  {
    name: "Round 1",
    startDate: "2024-03-18T04:00:00Z",
    endDate: "2024-03-23T03:59:59Z",
  },
  {
    name: "Round 2",
    startDate: "2024-03-23T04:00:00Z",
    endDate: "2024-03-25T03:59:59Z",
  },
  {
    name: "Sweet 16",
    startDate: "2024-03-25T04:00:00Z",
    endDate: "2024-03-30T03:59:59Z",
  },
  {
    name: "Elite 8",
    startDate: "2024-03-30T04:00:00Z",
    endDate: "2024-04-01T03:59:59Z",
  },
  {
    name: "Final Four",
    startDate: "2024-04-01T04:00:00Z",
    endDate: "2024-04-07T03:59:59Z",
  },
  {
    name: "Championship",
    startDate: "2024-04-07T04:00:00Z",
    endDate: "2024-04-09T03:59:59Z",
  },
];
