import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { HOST_SAVED_PICKS_BY_LEAGUE_QUERY } from "src/gql/queries";
import { useCarousel } from "../Carousel";
import styles from "./styles.scss";
import PlayerImage from "../Player/PlayerImage";
import TeamLogo from "../Team/TeamLogo";
import Dropdown, { DropdownItem } from "../Dropdown";
import { WEB_SPORTS_LIST } from "src/constants";
import AnalyticsService from "src/services/AnalyticsService";

type SavedPickConnection = {
  edges: SavedPickEdge[];
  pageInfo: {
    hasNextPage: boolean;
    afterCursor: string;
  };
};

type SavedPickEdge = {
  cursor: string;
  node: SavedPick;
};

type SavedPick = {
  id: string;
  analysis: string;
  notes: string;
  pick: string;
  betType: string;
  user: {
    displayName: string;
    imageUrl: string;
  };
  league: {
    id: string;
    name: string;
  };
  team: {
    id: string;
    preferredAbbreviation: string;
  };
  player: {
    id: string;
    imageUrl: string;
  };
};

const leagueOptions: DropdownItem[] = [
  { key: null as any, name: "All Sports" },
  ...WEB_SPORTS_LIST.map(({ key }) => ({ key, name: key })),
];

const HostSavedPicks = ({ sport = null }: { sport?: string | null }) => {
  const [league, setLeague] = useState<string | null>(sport);

  const { data, loading } = useQuery<{ getHostSavedPicksByLeague: SavedPickConnection }>(
    HOST_SAVED_PICKS_BY_LEAGUE_QUERY,
    {
      variables: { league },
    }
  );

  useEffect(() => setLeague(sport), [sport]);

  const savedPicks = data?.getHostSavedPicksByLeague?.edges ?? [];

  const hostPicksComponents = loading
    ? [1, 2, 3, 4, 5, 6].map((i) => <CardPlaceholder key={i} />)
    : savedPicks.map((savedPick) => <Card savedPick={savedPick} />);

  const { Carousel, CarouselNav } = useCarousel(hostPicksComponents, {
    contentHash: String(loading) + savedPicks.map((pick: SavedPickEdge) => pick.node.id).join(""),
    analyticTracking: () =>
      AnalyticsService.track("Host Picks Web - Scroll", { sport: league, location: window?.location?.href }),
  });

  return (
    <div className="host-saved-pick">
      <div className="header">
        <h2 className="header-title">Expert Analysis from BetQL Talent</h2>

        <div className="header-selector" style={sport ? { justifyContent: "flex-end" } : {}}>
          {!sport ? (
            <Dropdown
              className="sport-dropdown"
              items={leagueOptions}
              selectedIndex={leagueOptions.findIndex((o) => o.key === league)}
              onSelectItem={(o) => {
                setLeague(o.key as string);
                AnalyticsService.track("Host Picks Web - Switch Sport", { sport: league });
              }}
            />
          ) : null}

          <CarouselNav />
        </div>
      </div>

      {savedPicks.length ? <Carousel /> : null}

      {!loading && !savedPicks.length ? (
        <div className="no-picks">
          There are currently no host picks for this sport. View the 'All' section or another sport to see more picks.
        </div>
      ) : null}

      <style jsx>{styles}</style>
    </div>
  );
};

const CardPlaceholder = () => {
  return (
    <div className="host-pick-card">
      <div className="user-section">
        <div>
          <h2 className="placeholder" style={{ width: 100, height: 18 }} />
          <p className="title placeholder" style={{ width: 230, height: 18 }} />
        </div>

        <div className="placeholder" style={{ width: 40, height: 40 }} />
      </div>

      <div className="bet-section">
        <div className="placeholder" style={{ width: 40, height: 40 }} />

        <article>
          <h3 className="pick placeholder" style={{ width: 200, height: 20 }} />
          <p className="analysis placeholder" style={{ width: 280, height: 60 }} />
        </article>
      </div>
    </div>
  );
};

const Card = ({ savedPick }: { savedPick: SavedPickEdge }) => {
  const { analysis, pick, player, team, league, user } = savedPick.node;

  return (
    <article className="host-pick-card">
      <div className="user-section">
        <span className="username">{user.displayName}</span>

        <PlayerImage url={user.imageUrl} size={38} />
      </div>

      <div className="bet-section">
        {player ? (
          <PlayerImage url={player.imageUrl} size={48} />
        ) : (
          <TeamLogo
            containerClassName="team-logo-container"
            className="team-logo"
            teamAbbreviation={team.preferredAbbreviation}
            leagueName={league.name}
            size={50}
          />
        )}

        <div>
          <h3 className="pick">{pick}</h3>
          <p className="analysis">{analysis}</p>
        </div>
      </div>
    </article>
  );
};
export default HostSavedPicks;
